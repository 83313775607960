import React from "react";

const footerStyle = {
    textAlign: "center",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "14px",
    borderTop: "1px solid #cfcfcf",
    paddingTop: "10px",
    marginTop: "10px"
  };

export default function Footer() {
  return (
    <div id='footer' style={footerStyle}>
        &copy; 2021 BrickExperts
    </div>
  );
}