import React, { Component } from "react";
import './App.css';


import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect
} from "react-router-dom";
import MainPage from "./pages";
import ContactPage from "./pages/contact";
import NotFoundPage from "./pages/404"
import LegoCreatorWinterVillagePage from "./pages/lego-creator-winter-village";
import LegoCreatorModularPage from "./pages/lego-creator-modular";
import LegoCreatorVehiclePage from "./pages/lego-creator-vehicle";
import LegoCreatorOtherPage from "./pages/lego-creator-other";
import LegoIdeasPage from "./pages/lego-ideas";
import LegoDCPage from "./pages/lego-dc";
import LegoSuperMarioPage from "./pages/lego-super-mario";
import LegoTechnicPage from "./pages/lego-technic";
import LegoArchitecturePage from "./pages/lego-architecture";
import LegoCityPage from "./pages/lego-city";
import LegoHarryPotterPage from "./pages/lego-harry-potter";
import LegoDisneyPage from "./pages/lego-disney";
import LegoStarWarsPage from "./pages/lego-star-wars";
import LegoSeasonalPage from "./pages/lego-seasonal";
import LegoEmployeePage from "./pages/lego-employee-gifts";
import LegoMarvelPage from "./pages/lego-marvel";
import LegoGWPPage from "./pages/lego-gwp";
import LegoHouse from "./pages/lego-house";
import LegoExclusive from "./pages/lego-exclusive";
import LegoSimpsons from "./pages/lego-simpsons";

function App() {
  return (

    <Router>
      {/*All our Routes goes here!*/}
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/creator-winter-village" component={LegoCreatorWinterVillagePage} />
        <Route exact path="/creator-modular" component={LegoCreatorModularPage} />
        <Route exact path="/creator-vehicle" component={LegoCreatorVehiclePage} />
        <Route exact path="/creator-other" component={LegoCreatorOtherPage} />
        <Route exact path="/ideas" component={LegoIdeasPage} />
        <Route exact path="/dc" component={LegoDCPage} />
        <Route exact path="/super-mario" component={LegoSuperMarioPage} />
        <Route exact path="/technic" component={LegoTechnicPage} />
        <Route exact path="/architecture" component={LegoArchitecturePage} />
        <Route exact path="/city" component={LegoCityPage} />
        <Route exact path="/disney" component={LegoDisneyPage} />
        <Route exact path="/harry-potter" component={LegoHarryPotterPage} />
        <Route exact path="/star-wars" component={LegoStarWarsPage} />
        <Route exact path="/seasonal" component={LegoSeasonalPage} />
        <Route exact path="/employee-gifts" component={LegoEmployeePage} />
        <Route exact path="/marvel" component={LegoMarvelPage} />
        <Route exact path="/lego-house" component={LegoHouse} />
        <Route exact path="/gwp" component={LegoGWPPage} />
        <Route exact path="/exclusive" component={LegoExclusive} />
        <Route exact path="/simpsons" component={LegoSimpsons} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
    </Switch>
  </Router>    // <div id="app">
    //   <Header />
    //   <Body />
    //   <Footer />
    // </div>
  )
}

export default App;
