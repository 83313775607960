import React from "react";
import { Link } from "react-router-dom";
import Header from '../Components/Header';
import Body from '../Components/Body';
import Footer from '../Components/Footer';
//Functional Component 

const cssPageTitle = {
  textAlign: "center",
  fontFamily: "'Open Sans', sans-serif",
  textDecoration: "underline",
  fontSize: "20px"
}

const cssPageText = {
  paddingTop: "20px",
  textAlign: "center",
  fontFamily: "'Open Sans', sans-serif",
}

const NotFoundPage = () => {
  return (
    <div id="app">
      <Header />
        <div style={cssPageTitle}>404</div>
        <div style={cssPageText}>Oeps...De door jou gezochte pagina bestaat niet<br></br><br></br></div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;