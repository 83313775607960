import React from "react";
import { Link } from "react-router-dom";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../Pages.css';
//Functional Component 
const MainPage = () => {
  return (
    <div id="app">
      <Header />
      {/* <Body /> */}
      <div className="center">
        <div className="PageText">Kies een thema:</div>
        <ul>
            <li>
            <Link to="/creator-winter-village" className="listOption">Creator - Winter village</Link>
            </li>
            <li>
            <Link to="/creator-modular" className="listOption">Creator - Modular</Link>
            </li>
            <li>
            <Link to="/creator-vehicle" className="listOption">Creator - Voertuigen</Link>
            </li>
            <li>
            <Link to="/creator-other" className="listOption">Creator - Overig</Link>
            </li>
            <li>
            <Link to="/ideas" className="listOption">Ideas</Link>
            </li>
            <li>
            <Link to="/dc" className="listOption">DC</Link>
            </li>
            <li>
            <Link to="/super-mario" className="listOption">Super Mario</Link>
            </li>
            <li>
            <Link to="/disney" className="listOption">Disney</Link>
            </li>
            <li>
            <Link to="/harry-potter" className="listOption">Harry Potter</Link>
            </li>
            <li>
            <Link to="/technic" className="listOption">Technic</Link>
            </li>
            <li>
            <Link to="/architecture" className="listOption">Architecture</Link>
            </li>
            <li>
            <Link to="/city" className="listOption">City</Link>
            </li>
            <li>
            <Link to="/star-wars" className="listOption">Star Wars</Link>
            </li>
            <li>
            <Link to="/marvel" className="listOption">Marvel</Link>
            </li>
            <li>
            <Link to="/seasonal" className="listOption">Seasonal</Link>
            </li>
            <li>
            <Link to="/gwp" className="listOption">GWP</Link>
            </li>
            <li>
            <Link to="/lego-house" className="listOption">Lego House</Link>
            </li>
            <li>
            <Link to="/exclusive" className="listOption">Exclusive</Link>
            </li>
            <li>
            <Link to="/simpsons" className="listOption">The Simpsons</Link>
            </li>
            <li>
            <Link to="/employee-gifts" className="listOption">Employee Gifts</Link>
            </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default MainPage;