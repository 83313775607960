import React from 'react';
import ReactDOM from 'react-dom';
//import { data } from './Data/data';

//import './index.css';
import App from './App';
import ChartOverview from './Components/ChartsOverview';
import Select from './Elements/Select'

function loadCharts() {
  const getData=()=>{
    fetch('Data/chartdata.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        //console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);

        var selCatData = myJson.chartData.filter((d) => {
          return d.id === document.getElementById("selThemes").value;
        })[0];
        var a = JSON.stringify(selCatData)
        var newData = JSON.parse(a);
        if (newData.id === "CreatorWinter" || newData.id === "CreatorModular") {
          newData.chartData.reverse();
        }
        
        ReactDOM.render(<ChartOverview data = {newData.chartData} />, document.getElementById('content'))
      });
  }
  var data = getData();
}

ReactDOM.render(
  <React.StrictMode>
    <div data-id="bb">
      <App />

      
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// const selItems = [{label: "Creator Expert - Winter Village", value: "CreatorWinter"},{label: "Creator Expert - Modular", value: "CreatorModular"},{label: "Creator Expert - Vehicle", value: "CreatorVehicle"},{label: "Creator Expert - Other", value: "CreatorOther"},{label: "Ideas", value: "Ideas"},{label: "DC", value: "DC"},{label: "Technic", value: "Technic"},{label: "SuperMario", value: "SuperMario"},{label: "Architecture", value: "Architecture"},{label: "City", value: "City"}];
// ReactDOM.render(
//   <Select items={selItems} onChange={loadCharts} id={"selThemes"} />, document.getElementById("selectTheme")
// )




//  loadCharts();
