import React, { Component } from 'react'
import Highcharts, { extend } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const chartStyle = {
    height: "100px"
  };
  
  const imgStyle = {
    width: "100%",
    maxWidth: "350px",
    display: "inline-block"
    //padding: "10px"
  }

  export default class Chart extends Component {
      constructor(props) {
          super(props);
        this.props = props
          this.state = {

          }
      }

      render() {
        return(
            <div style={imgStyle}>
            <HighchartsReact
                highcharts={Highcharts}
                oneToOne={true}
                allowChartUpdate = { true }
                immutable = { false }
                updateArgs = { [true, true, true] }
                options={this.props.options}
                key={Math.random()}
            />
            </div>
        )
    }
}

// export default function Chart (props) {
// return(
//     <div style={imgStyle}>
//     <HighchartsReact
//         highcharts={Highcharts}
//         options={props.options}
//         oneToOne={true}
//     />
//     </div>)
// }

//ReactDOM.render(<Chart />, document.getElementById('root'))