import React from "react";
import ReactDOM from 'react-dom';
import LegoCharts from "./Components/LegoCharts"

export function loadCharts(legoTheme) {
    const getData=()=>{
      fetch('Data/chartdata.json'
      ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }
      )
        .then(function(response){
          //console.log(response)
          return response.json();
        })
        .then(function(myJson) {
          console.log(myJson);
  
          var selCatData = myJson.chartData.filter((d) => {
            return d.id === legoTheme;
          })[0];
          var a = JSON.stringify(selCatData)
          var newData = JSON.parse(a);
          if (newData.id === "CreatorWinter" || newData.id === "CreatorModular") {
            newData.chartData.reverse();
          }
          
          ReactDOM.render(<LegoCharts data = {newData.chartData} />, document.getElementById('content'))
        });
    }
    var data = getData();
  }

export const cssPageTitle = {
    textAlign: "center",
    fontFamily: "'Open Sans', sans-serif",
    textDecoration: "underline"
  }

