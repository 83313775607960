import React from "react";
import Logo from './Logo';

const selStyle = {
    textAlign: "center",
    padding: "10px 0",
    borderBottom: "1px solid #cfcfcf",
    marginBottom: "20px"
}

export default function Header() {
  return (
      <div>
        <Logo />
        <div id="selectTheme" style={selStyle}></div>
    </div>
  );
}