import React from "react";

//Functional Component 
const ContactPage = () => {
  return (
    <div>
        contact
    </div>
  );
};

export default ContactPage;