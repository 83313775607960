import React from "react";
import Chart from "./Charts";

const divStyle = {
    textAlign: "center",
    paddingTop: "20px"
  };
  
  const imgStyle = {
    width: "100%",
    maxWidth: "350px",
    padding: "10px"
  }

export default function LegoCharts(props) {
    let myTest = [];

    props.data.forEach((item, i) => myTest.push(
      {
        credits: {
          enabled: false
        },
        title: {
        text: item.code + " - " + item.title + "<br>(" + item.release + " - " + (item.termination !== null ? item.termination : "") + ")",
        style: {
          fontFamily: 'sans-serif, Verdana',
          fontSize: '12px'
        }
      },
      yAxis: {
          tickInterval: 10,
          title: {
            text: 'Prijs'
          }
      },
      xAxis: {
        categories: item.xAxis
      },
      series: [{
        type: 'column',
        name: 'Min',
        data: item.min,
        color: '#ccddff',
      }, {
        type: 'column',
        name: 'Max',
        data: item.max,
        color: '#99bbff'
      }, {
        type: 'spline',
        name: 'Avg',
        data: item.avg,
        lineColor: '#001a4d',
        color: '#001a4d',
        lineWidth: 4,
        marker: {
          enabled: false,
          lineWidth: 2,
          lineColor: '#001a4d',
          fillColor: 'white'
        }
      }, {
          type: 'spline',
          name: 'Adv pr.',
          data: item.adv,
          lineColor: '#1a66ff',
          lineWidth: 4,
          dashStyle: 'ShortDot',
          color: '#1a66ff',
          marker: {
            enabled: false,
            lineWidth: 2,
            lineColor: '#1a66ff', 
            fillColor: 'white'
          }
        }]})
        );

    const myArrMadeFromForEach = [];
    myTest.forEach((item, i) => myArrMadeFromForEach.push(<Chart key={i} options={item} />));
    return (
        <div style={divStyle}>
        {myArrMadeFromForEach}
        </div>
    );
}