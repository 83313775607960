import React, { useEffect } from "react";
 import Header from '../Components/Header';
 import Footer from '../Components/Footer';
 import * as helper from "../helper";
 import '../Pages.css';

const LegoPage = () => {
  useEffect(()=>{
    helper.loadCharts("StarWars");
  })

  return (
    <div>
      <Header />
      <div className="PageTitle">
          Star Wars
      </div>
      <div id='content'></div>
      <Footer />
    </div>
  );
};



export default LegoPage;