import React, { useEffect } from "react";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import * as helper from "../helper";
import '../Pages.css';

const cssPageTitle = {
  textAlign: "center",
  fontFamily: "'Open Sans', sans-serif",
  textDecoration: "underline"
}

//Functional Component 
const LegoPage = () => {
  useEffect(()=>{
    helper.loadCharts("Marvel");
  })

  return (
    <div>
      <Header />
      <div className="PageTitle">
          Creator - Marvel
      </div>
      <div id='content'></div>
      <Footer />
    </div>
  );
};



export default LegoPage;