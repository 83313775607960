import React from "react";

const logoStyle = {
  textAlign: "center",
  cursor: "pointer"
};

export default function Logo() {
  return (
    <div id="logo" style={logoStyle}>
      <img src={"../img/logo.svg"} width="150px" onClick={()=>{document.location='/'}} />
    </div>
  );
}
